import { burger } from "./modules/header/burger.js";
import { modals } from "./modules/modals.js";
import { reviews } from "./modules/reviews.js";
import { beforeafter } from "./modules/beforeafter.js";
import { doctors } from "./modules/doctors.js";
import { services } from "./modules/services.js";
import { slider } from "./modules/slider.js";
import { info } from "./modules/info.js";
import { questions } from "./modules/questions.js";
import { gallery } from "./modules/gallery.js";
import { popup } from "./modules/popup.js";
document.addEventListener("DOMContentLoaded", function (event) {
  burger();
  slider();
  reviews();
  modals();
  services();
  info();
  popup();
  beforeafter();
  doctors();
  gallery();
  questions();
});
