export const burger = () => {
  const btns = document.querySelectorAll(".header_burger");
  const menus = document.querySelectorAll(".header_menu_mob");
  const page = document.querySelector("#page");
  const menuItems = document.querySelectorAll(".header_menu_mob a");

  btns.forEach((btn) => {
    btn.addEventListener("mouseenter", () => {
      btn.classList.add("hover");
    });
    btn.addEventListener("mouseleave", () => {
      btn.classList.remove("hover");
    });
    btn.addEventListener("click", () => {
      btn.classList.toggle("active");
      btn.classList.remove("hover");
      toggleMenu();
    });
  });

  const toggleMenu = () => {
    menus.forEach((menu) => {
      menu.classList.toggle("scale-y-0");
    });
    page.classList.toggle("active");
    document.querySelector("body").classList.toggle("overflow-hidden");
  };

  const closeMenu = () => {
    btns.forEach((btn) => btn.classList.remove("active"));
    menus.forEach((menu) => menu.classList.add("scale-y-0"));
    page.classList.remove("active");
    document.querySelector("body").classList.remove("overflow-hidden");
  };

  menuItems.forEach((item) => {
    item.addEventListener("click", (event) => {
      const href = item.getAttribute("href");
      if (!href.startsWith("#")) {
        closeMenu();
      }
    });
  });

  document.addEventListener("click", (event) => {
    const isClickInside =
      Array.from(btns).some((btn) => btn.contains(event.target)) ||
      Array.from(menus).some((menu) => menu.contains(event.target));

    if (!isClickInside) {
      closeMenu();
    }
  });

  menus.forEach((menu) => {
    menu.addEventListener("click", (event) => {
      event.stopPropagation();
    });
  });
};
