export const questions = () => {
  $(document).ready(function () {
    $(".answer").hide();
    $(".question_toggle").on("click", function () {
      var targetId = $(this).data("target");
      $(this).toggleClass("active");
      $("#" + targetId).slideToggle(300);
      $(".answer")
        .not("#" + targetId)
        .slideUp(300);
      $(".question_toggle").not(this).removeClass("active");
    });
  });
};
